.img-card {
  background: $brand-primary;
}

.resource-card {
  cursor: pointer;
  &:hover {
    background-color: lighten($brand-primary, 10%);
  }
}

.resource-img-card {
  max-width: 100%;
  height: auto;
}

.callout-brand-primary {
  border-left-color: $brand-primary;
}

.callout-brand-secondary {
  border-left-color: $brand-secondary;
}

.fixed-container {
  height: 450px;
  overflow-y: auto;
}
