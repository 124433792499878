.loading-spinner {
  font-size: 250%;
  text-align: center;
  &-contextual {
    @extend .loading-spinner;
    height: 100%;
    width: 100%;
    min-height: 50px;
    > .loading-spinner-contextual-icon {
      position: absolute;
      font-size: 50px;
      top: calc(50% - 25px);
      left: calc(50% - 25px);
    }
  }
  i {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}