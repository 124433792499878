#login-form,
#signup-form {
  a {
    color: #0000ee;
  }
  a:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.form-row {
  display: flex;

  label {
    flex: 1;
  }

  input {
    flex: 2;
  }
}
