#imageUploadPlaceholder {
  min-height: 100px;
  i {
    padding-top: 80px;
  }
}


#global-image-form {
  i {
    font-size: 1.5em;
  }

  .upload-photo {
    cursor: pointer;
  }

  .primary-bg {
    background: #269796;
  }

  .centered-circle {
    height: 200px;
    width: 200px;
    border-radius: 200px;
    display: block;
    margin: auto;
  }

  #imageUploadPlaceholder i {
    padding-top: 80px;
  }

  #imageFrame {
    padding-bottom: 0 !important;
  }
}

.upload-photo-global {
  i {
    font-size: 1.5em;
  }

  .upload-photo {
    cursor: pointer;
  }

  .primary-bg {
    background: #269796;
  }
  #imageUploadPlaceholder {
    padding-top: 0px;
    padding-bottom: 60px;
  }
  #imageFrame {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    min-height: 200px;
  }
}