.image-upload-header{
  height: 200px;
  width: 100%;
  background-size: cover;
}

.error { border: 1px solid red; }

// tables
.table th, .table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #cfd8dc;
}