//@import '~bootstrap/scss/bootstrap.scss';


// Opacity Helper
$i: 100;
@while $i > -1 {
  .opacity-#{$i} {
    opacity: $i / 100;
  }
  $i: $i - 1;
}

// MinHeight Helper
$e: 100;
@while $e > -1 {
  .minH-#{$e} {
    min-height: #{$e}vh;
  }
  $e: $e - 1;
}


// MaxHeight Helper
$e: 100;
@while $e > -1 {
  .maxH-#{$e} {
    min-height: #{$e}vh;
  }
  $e: $e - 1;
}
