@each $variant, $color in (primary: $brand-primary,
        secondary: $gray-lighter, success: $brand-success,
        info: $brand-info, warning: $brand-warning, danger: $brand-danger) {
  .modal-#{$variant} {

    .modal-content {
      border-color: $color;
    }

    .modal-header {
      color: #fff;
      background-color: $color;
    }
  }
}

.fade.in {
  opacity: 1;
}
.modal.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}

.modal-backdrop .fade .in {
  opacity: 0.5 !important;
}


.modal-backdrop.fade {
  opacity: 0.5 !important;
}