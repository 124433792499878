.round-image-wrapper {
    text-align: center;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    height: 55px;
    width: 55px;
    background-position: center;
  
    .round-image {
      width: 100%;
    }
  }