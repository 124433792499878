
// This whole thing needs to be reorganized. Why is this even here? 

#new-team-form {
  i {
    font-size: 1.5em;
  }

  .upload-photo {
    cursor: pointer;
  }

  .primary-bg {
    background: #269796;
  }

  .centered-circle {
    height: 200px;
    width: 200px;
    border-radius: 200px;
    display: block;
    margin: auto;
  }

  #imageUploadPlaceholder i {
    padding-top: 80px;
  }

  #imageFrame {
    padding-bottom: 0 !important;
    background-size: 90% auto !important; 
    background-repeat: no-repeat !important;
  }

}

#plan-form{
  height: 50px !important;
}
.dropdown {
    position: relative;
    display: inline-block;
}



.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  z-index: 1;
  // height: 200px;
  margin-left: -120px;
  // overflow: scroll;
  &-inner{
    height: 120px;
    overflow: scroll;    
    p{
      transition: .3s; 
      &:hover{
      background-color: #269796;
      color: #fff;      
      } 

    }
   
  }
}



// AutoComplete 
.dropdown-content > div{
  // border: solid 1px #444;   
  width: 280px;
  transition: .3s;
  // &:hover{
    // background-color: #269796;
    // color: #fff;
  // }
}  
.add-new-top{
  background-color:#fff;
  p{
  margin-bottom: 20px;
  border: solid  #269796 1px;
  color:  #269796;   
  transition: .3s; 
    &:hover{
      background-color: #269796;
      color: #fff;      
    }
  }

}
.search{
  position: fixed;
  display:none;
  background: #fff;
}
.add-new-bottom{
    display: none;
    top: 250px;
    position: absolute;
    width: 300px;
    background: #fff;
    z-index: 99;
    margin-left: -120px;
}

.dropdown:hover .dropdown-content, .dropdown:hover .add-new-bottom {
    display: block;
}