#BookingAdmin {
  height: 100vh;
  background-color: $brand-primary;
  .card {
    min-height: 33vh;
  }
}

#close-modal {
  text-align: right;
  padding-bottom: 10px;
}

#close-modal:hover {
  cursor: pointer;
}
