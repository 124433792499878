img.quill-upload-progress {
  opacity: 1;
}

.ql-container.ql-snow {
  background-color: white;
  min-height: 120px;

  .ql-editor {
    p {
      font-size: 14px;
    }

    h6 {
      font-size: 12px;
    }


  }
}