.message-member-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;

  p {
    margin-bottom: 0px;
  }

  .member-info-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .member-name {
      font-size: 22px;
    }

    .member-avatar-wrapper {
      margin-right: 15px;
    }
  }

  .member-plus-icon {
    font-size: 20px;

    &:hover {
      cursor: pointer;
    }
  }
}
