.Toastify__toast--success {
  background: #4dbd74 !important;
}
.Toastify__toast--warning {
  background: #ffc107 !important;
}
.Toastify__toast--danger {
  background: #f86c6b !important;
}
.Toastify__toast--info {
  background: #63c2de !important;
}
