.rbc-calendar {
  .rbc-toolbar {
    flex-wrap: nowrap;
  }
  .rbc-time-header {
    flex-direction: row;
  }

  .rbc-time-view-resources {
    .rbc-day-slot, .rbc-header {
      min-width: 200px;
    }
  }
}