//@import '~bootstrap/scss/bootstrap.scss';

.border{
	border: 1px $color-gray solid;
	&-bottom-1 	, &-bottom{
		border-bottom: 1px $color-gray-light solid;
	}
	&-top-1 	, &-top{
		border-top: 1px $color-gray-light solid;
	}
	&-left-1 	, &-left{
		border-left: 1px $color-gray-light solid;
	}
	&-right-1 	, &-right{
		border-right: 1px $color-gray-light solid;
	}
}


$i: 100;
@while $i > -1 {
  .minH-#{$i} {
    min-height:calc(#{$i} * 1vh);
  }
  $i: $i - 1;
}
