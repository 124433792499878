.active-onboarding-card {
  background-color: #289796;

  .active-onboarding-icon {
    color: white;
  }

  .active-onboarding-text {
    color: white;
  }
}

.tabs-container {
  display: flex;
  justify-content: flex-end;
  margin-top: -60px;
}

.btn-group-container {
  background-color: transparent;
  border: 1px solid white;
  border-radius: 5px;

  .btn-graph {
    background-color: transparent;

    &:hover {
      background-color: white;
      color: #29404f;
    }
  }
}

.listview-header-container {
  background-color: #29404f;
  margin-bottom: 10px;
  justify-content: space-between;
}

.listview-scroll-list {
  height: 250px;
  overflow-y: scroll;
  width: 95%;
  margin-bottom: 10px;
}

.listview-title {
  color: white !important;
  font-size: 18px !important;
  font-weight: bold !important;
}

.listitem-title {
  font-size: 15px;
  font-weight: 600;
  width: 190px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.listitem-member-title {
  font-size: 15px;
  font-weight: 600;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.listitem-description {
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.listitem-event {
  text-align: right;
  font-size: 13px;
  font-weight: 600;
}

.listitem-date {
  text-align: right;
  font-size: 12px;
}

.listview-add-icon {
  font-size: 18px !important;
  margin-right: 10px;
}

.listview-container {
  display: flex;
  flex-direction: row;
}

.listview-wrapper {
  &:after {
    height: 1px;
    display: block;
    width: 100%;
    background: lightgrey;
    content: '';
  }
}

.listview-booking-wrapper {
  display: flex;
  justify-content: space-between;
}

.member-profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-top: 5px;
  margin-right: 8px;
  background: contain;
}

// Breakpoints for Screen Resolutions

@media (min-width:320px)  {
  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  .listitem-event {
    text-align: right;
    font-size: 11px;
    font-weight: 600;
  }

  .listitem-date {
    text-align: right;
    font-size: 10px;
  }

  .rooms-chart-wrapper {
    margin-top: -10px;
  }
}
@media (min-width:480px)  {
  /* smartphones, Android phones, landscape iPhone */
  .listitem-event {
    text-align: right;
    font-size: 11px;
    font-weight: 600;
  }
}
