//@import '~bootstrap/scss/bootstrap.scss';

a.btn, button.btn{
	&-primary{
		color: #fff;
		color: $white;
		background-color: $brand-primary;
	}
	&-rounded{
		border-radius: 30px;
	}
	&-outline-white{
		border: 2px solid #fff;
		background-color: transparent;
		color: #fff;
	}
	&-huge{
		font-size: 32px;
    	border-radius: 70px;
    	padding: 25px;
    	font-weight: bold;
	}
	&-link-secondary-primary{
		color: #b0bec5;
		&:hover{
			color: $brand-primary;
		}
	}

}



.btn-primary{

}

.btn-primary:disabled {

	background-color: lighten($brand-primary, 20%);
}



// Custom Toggle
//----------------
$color-1: $brand-primary;
$color-2: #FFFFFF;
$color-3: $brand-primary;
$color-4: #efefef;
//----------------

//----------------
.pricing-switcher {
	font-size: 12px;
	$bg-padding: 5px;
	$button-width: 100%;
	$button-height: 23px;
	$animation-time: .3s;
	text-align: center;
	.fieldset {
		display: inline-block;
		position: relative;
		padding: $bg-padding;
		padding: 0px;
		border-radius: 0px;
		width: 100%;
    	background-color: #efefef;
		input[type="radio"] {
			position: absolute;
			opacity: 0;
		}
		label {
			position: relative;
			z-index: 1;
			display: inline-block;
			float: left;
			width: $button-width/2;
			height: $button-height;
			line-height: 32px;
			cursor: pointer;
			color: lighten($color-1, 20%);
			text-transform: uppercase;
			text-decoration: none;
			font-weight: bold;
			transition-delay: .1s;
			transition: color $animation-time ease;
		}
		input[type="radio"]:checked + label {
			color: $color-2;
			text-decoration: none;
		}

		input[type="radio"]:checked + label + .switch,
		input[type="radio"]:checked + label:nth-of-type(n) + .switch {
			/* use label:nth-of-type(n) to fix a bug on safari with multiple adjacent-sibling selectors*/
			-webkit-transform: translateX($button-width);
			-moz-transform: translateX($button-width);
			-ms-transform: translateX($button-width);
			-o-transform: translateX($button-width);
			transform: translateX($button-width);
		}
		.switch {
			/* floating background */
			position: absolute;
			top: $bg-padding;
			left: $bg-padding;
			height: $button-height;
			width: $button-width/2;
			background-color: $color-1;
			border-radius: 0px;
			transition: transform $animation-time;
		}
	}
}


.pricing-switcher-1 {
	font-size: 12px;
	$bg-padding: 5px;
	$button-width: 100%;
	$button-height: 23px;
	$animation-time: .3s;
	text-align: center;
	.fieldset {
		display: inline-block;
		position: relative;
		padding: $bg-padding;
		padding: 0px;
		border-radius: 0px;
		width: 100%;
    	background-color: #efefef;
		input[type="radio"] {
			position: absolute;
			opacity: 0;
		}
		label {
			position: relative;
			z-index: 1;
			display: inline-block;
			float: left;
			width: $button-width/2;
			height: $button-height;
			line-height: 32px;
			cursor: pointer;
			color: lighten($color-1, 20%);
			text-transform: uppercase;
			text-decoration: none;
			font-weight: bold;
			transition-delay: .1s;
			transition: color $animation-time ease;
		}
		input[type="radio"]:checked + label {
			color: $color-2;
			text-decoration: none;
		}

		input[type="radio"]:checked + label + .switch,
		input[type="radio"]:checked + label:nth-of-type(n) + .switch {
			/* use label:nth-of-type(n) to fix a bug on safari with multiple adjacent-sibling selectors*/
			-webkit-transform: translateX($button-width);
			-moz-transform: translateX($button-width);
			-ms-transform: translateX($button-width);
			-o-transform: translateX($button-width);
			transform: translateX($button-width);
		}
		.switch {
			/* floating background */
			position: absolute;
			top: $bg-padding;
			left: $bg-padding;
			height: $button-height;
			width: $button-width/2;
			background-color: $color-1;
			border-radius: 0px;
			transition: transform $animation-time;
		}
	}
}