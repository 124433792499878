// Card Basic
.z-depth-1 {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.card {
  font-weight: 400;
  border-radius: 0.3rem;
  &:not([class*='card-outline-']) {
    border: 0;
    @extend .z-depth-1;
  }
  .card-body {
    position: relative;
    h5 {
      margin-bottom: 1rem;
      font-size: 1rem;
      font-weight: 400;
    }
    h3 {
      margin-bottom: 1rem;
      font-weight: 400;
    }
    h4 {
      font-weight: 400;
    }
    p {
      margin-bottom: 1rem;
    }
  }
  .card-title {
    a {
      transition: 0.3s;
      &:hover {
        transition: 0.3s;
      }
    }
  }
  .card-text {
    font-size: 0.9rem;
    color: #747373;
    font-weight: 400;
  }
  .card-footer {
    &.links-light {
      a {
        font-size: 15px;
        color: #757575;
        &:hover {
          color: #d50000;
          transition: 0.4s;
        }
        .fa {
          font-size: 17px;
        }
      }
    }
  } // .card-footer {
  // 	padding-bottom: 0;
  // 	font-size:1.2rem;
  // 	&:after {
  // 	display:block;
  // 		content: "";
  // 		clear:both;
  // 	}
  // 	.card-link {
  // 		color: inherit;
  // 	}
  // }
}
