//@import '~bootstrap/scss/bootstrap.scss';

$color-gray: #cccccc; 
$color-gray-light: lighten($color-gray, 15%);


.bg-light{
	background-color: $color-gray-light;
}

.primary-color {
	color: #479494;
}

.danger-color {
	color: #d9534f;
}