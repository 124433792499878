@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  ul,
  ol {
    list-style: revert;
    list-style-position: inside;
  }
}

@layer utilities {
  .tw-columns-2 {
    columns: 2;
  }
  .tw-break-inside-avoid-column {
    break-inside: avoid-column;
  }
  .tw-break-after-column {
    break-after: column;
  }
  .tw-max-w-fit {
    max-width: fit-content;
  }
}

@layer components {
  .table-container {
    overflow-x: auto;
  }

  @media (max-width: 768px) {
    .table th,
    .table td {
      padding: 0.5rem 1rem;
    }

    .table th {
      font-size: 0.75rem;
    }

    .table td {
      font-size: 0.875rem;
    }
  }
}
