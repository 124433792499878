// Modal
#BookingAuth {
  // position: absolute;
  max-width: calc(100vw - 60px);
  min-height: 80vh;
  // height: calc(100vh - 60px);
  margin: 30px;
  // overflow: hidden;
  // position: fixed;
  .booking-auth-header {
    background-color: #fff;
    padding: 20px;
    border-bottom: solid 1px #ccc;
    .btn-link {
      // margin-top: 20px;
      font-size: 30px;
    }
  }
  .modal-content {
    height: calc(100vh - 60px);
    overflow: scroll;
  }
  .member-avatar {
    height: 200px;
    width: 200px;
    border-radius: 100px;
    background-position: center;
    background-size: cover;
  }
  .passwordForm {
    height: calc(100vh - 80px);
    div {
      // border: solid 1px #000;
    }
  }
}
